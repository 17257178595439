header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: 'Roboto', sans-serif;
}

header > div {
  font-size: 26px;
  color: #ff6b6b;
  font-weight: 700;
  letter-spacing: 0.5px;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  transition: color 0.3s ease;
  line-height: 1.5;
}

nav ul li a:hover {
  color: #ff6b6b;
}
