table {
  border-spacing: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-collapse: separate;
  border-radius: 8px;
  overflow: hidden;
}

th,
td {
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  color: #333;
}

th {
  background-color: #fff;
  color: #000;
}

td {
  border-right: none;
}

tr:last-child td {
  border-bottom: none;
}

td:last-child {
  border-right: none;
}
