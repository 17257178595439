.card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
}

.card h2 {
  color: #333;
  margin-top: 0;
}

.card-content {
  color: #666;
}
